import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_EditForm = _resolveComponent("EditForm")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/fmPayeeAccount/queryPayeeAccountListWithPage",
      rowId: "id",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "paymentAccount",
      selection: true,
      onSelectionChange: _ctx.handleSelectionChange
    }, {
      header: _withCtx(() => [
        (_ctx.hasPermission('erp:fmPayeeAccount:savePayeeAccount'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "erp-primary__btn ml-8",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.add(1)))
            }, " 新增账号 "))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:fmPayeeAccount:savePayeeAccount'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "erp-primary__btn ml-8",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.add(2)))
            }, " 编辑账号 "))
          : _createCommentVNode("", true)
      ]),
      right: _withCtx(() => [
        _createElementVNode("div", {
          class: "erp-default__btn",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
        }, "返回")
      ]),
      _: 1
    }, 8, ["columOptions", "onSelectionChange"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.dialog.visible,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialog.visible = false)),
      erp: "",
      class: "no-padding fix-top-header"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_EditForm, {
          formType: _ctx.formType,
          onCancel: _ctx.cancel,
          data: _ctx.rowData
        }, null, 8, ["formType", "onCancel", "data"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}