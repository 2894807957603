import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();
  const status = [
    { label: '正常', value: 0 },
    { label: '禁用', value: 1 },
  ];
  const columnList: ErpTableOptions[] = [
    {
      label: '收款方类型',
      prop: 'payeeType',
      propDesc: 'payeeTypeDesc',
      minWidth: 140,
      value: [],
      type: 'multiSelect',
      options: 'payee_type',
    },
    {
      label: '收款方',
      prop: 'refName',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '状态',
      prop: 'isDisable',
      propDesc: 'isDisableDesc',
      minWidth: 120,
      value: 0,
      type: 'signSelect',
      options: status,
    },
    {
      label: '收款方名称',
      prop: 'accountName',
      minWidth: 130,
      value: '',
      type: 'input',
    },
    {
      label: '收款方账号',
      prop: 'accountNo',
      minWidth: 130,
      value: '',
      type: 'input',
    },
    {
      label: '开户银行名称',
      prop: 'bankName',
      minWidth: 140,
      value: '',
      type: 'input',
    },
    {
      label: '开户行支行名称',
      prop: 'branchName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '开户行所在城市',
      prop: 'addressCity',
      minWidth: 170,
      value: '',
      type: 'input',
      component: defineComponent({
        template: `
        {{handleName()}}
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup(props) {
          const handleName = () => {
            const municipalityArr = ['北京市', '上海市', '天津市', '重庆市'];
            let name = props.row.addressCity;
            if (municipalityArr.includes(props.row.addressProvince)) {
              name = props.row.addressProvince;
            }
            return name;
          };
          return { handleName };
        },
      }),
    },
    {
      label: '备注',
      prop: 'remark',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStartDate', 'createdTimeEndDate'],
    },
    {
      label: '更新人',
      prop: 'lastOperator',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '更新时间',
      prop: 'updateTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['updateTimeStartDate', 'updateTimeEndDate'],
    },
  ];
  return { columnList, tableRef };
};
