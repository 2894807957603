
import { defineComponent, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import EditForm from './add.vue';
import { errorTip } from '@/utils/publicMethods';

import router from '@/router';
export default defineComponent({
  name: 'paymentAccount',
  components: { ErpList, EditForm },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    const { visible, showVisible, closeVisible } = useVisible();
    const formType = ref(1);
    const data = reactive({
      rowData: null,
      selRowArr: [],
    });
    const dialog = reactive({
      visible: false,
    });
    /** 新增 */
    const add = (type) => {
      formType.value = type;
      if (type === 1) {
        dialog.visible = true;
      }
      if (type === 2) {
        const len = data.selRowArr.length;
        if (len === 1) {
          data.rowData = data.selRowArr[0];
          dialog.visible = true;
        } else {
          errorTip('请选中一个收款账号');
        }
      }
    };
    // 打印
    const back = () => {
      router.push('/erp/financial/payment');
    };
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      dialog.visible = false;
      if (status) {
        refreshTable();
      }
    };
    const handleSelectionChange = (val) => {
      data.selRowArr = val;
    };

    return {
      handleSelectionChange,
      add,
      back,
      dialog,
      formType,
      ...toRefs(data),
      cancel,
      closeVisible,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
    };
  },
});
