<template>
  <div class="payment-account-form">
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">
        {{ formType == 1 ? '新增账号' : '编辑账号' }}
      </div>
      <div class="erp-form-bar">
        <el-button size="small" type="primary" class="erp-primary__btn" @click="submit">保存</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16" type="flex">
            <el-col :span="12">
              <el-form-item label="收款方类型" prop="payeeType">
                <el-select v-model="formData.payeeType" class="mali-full__input" clearable filterable @change="payeeTypeChange" placeholder="请选择收款方类型">
                  <el-option v-for="item in Options.payee_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款方" :prop="formData.payeeType != 3 ? 'refName' : ''">
                <el-input
                  v-if="formData.payeeType === 5 || !formData.payeeType"
                  :maxlength="10"
                  v-model="formData.refName"
                  clearable
                  placeholder="请输入收款方"
                  @blur="valChinese($event, 'refName')"
                  class="mali-full__inputcom"
                ></el-input>
                <el-input
                  v-if="formData.payeeType === 3"
                  :maxlength="10"
                  v-model="formData.refName"
                  clearable
                  :placeholder="formData.payeeType != 3 ? '请输入收款方' : ''"
                  :disabled="true"
                  class="mali-full__inputcom"
                ></el-input>
                <el-select
                  v-if="formData.payeeType === 1 || formData.payeeType === 2 || formData.payeeType === 4"
                  v-model="formData.refName"
                  class="mali-full__input"
                  clearable
                  filterable
                  remote
                  :remote-method="getAccountNameOption"
                  @blur="initAccountNameOption"
                  @change="getrefId"
                  placeholder="请输入收款方"
                >
                  <el-option v-for="item in accountNameOption" :key="item.refId" :label="item.refName" :value="item.refId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款方名称" prop="accountName">
                <el-input v-model="formData.accountName" clearable :maxlength="40" class="mali-full__inputcom" placeholder="请输入收款方名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款方账号" prop="accountNo">
                <el-input
                  v-model="formData.accountNo"
                  clearable
                  :maxlength="25"
                  class="mali-full__inputcom"
                  placeholder="请输入收款方账号"
                  @blur="valInteger($event, 'accountNo')"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户行银行名称" prop="bankName">
                <el-select
                  v-model="formData.bankName"
                  class="mali-full__input"
                  clearable
                  filterable
                  remote
                  @change="getBankId"
                  :remote-method="getBankNameOption"
                  @blur="initBankNameOption"
                  placeholder="请输入开户行银行名称"
                >
                  <el-option v-for="item in bankNameOption" :key="item.id" :label="item.shortName" :value="item.shortName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开户行支行名称" prop="branchName">
                <el-input v-model="formData.branchName" clearable :maxlength="40" class="mali-full__inputcom" placeholder="请输入开户行支行名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="cityValid"
                :rules="[
                  {
                    required: true,
                    message: '请选开户行所在择城市',
                    trigger: 'blur',
                  },
                ]"
                label="开户行所在城市"
                :class="{
                  novalidate: !validateAddress,
                  municipality: isMunicipality,
                  'edit-type': formType === 2,
                }"
                class="contact-address"
              >
                <cityArea @change="getBankAddress" :data="bankAddress" :level3="false" :size="'medium'" :isMunicipality="true"></cityArea>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="formType == 2">
              <el-form-item label="状态" prop="isDisable">
                <el-select v-model="formData.isDisable" class="mali-full__input" clearable filterable remote placeholder="请选择状态">
                  <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  :maxlength="30"
                  v-model="formData.remark"
                  clearable
                  resize="none"
                  placeholder="请输入备注"
                  type="textarea"
                  class="mali-full__inputcom h88"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import cityArea from '@/components/CitySelect/CitySelect.vue';
import { store } from '@/store';
export default {
  props: ['formType', 'data'],
  components: { cityArea },
  data() {
    return {
      debounce: 500,
      formData: {
        // 绑定表单数据
        id: null,
        payeeType: null, // 收款方类型
        refName: null, // 收款方
        refId: null, // 关联对象ID
        accountName: '', // 收款方名称
        accountNo: '', // 收款方账户
        addressCity: '', // 地址-城市
        addressProvince: '', // 地址-省份
        bankName: '', // 开户行银行名称
        bankId: '', // 银行主键id
        branchName: '', // 开户行支行名称 ,
        addressCityId: null, // 城市id
        addressProvinceId: null, // 省id
        remark: null, // 备注
        version: null, // 版本号
        cityValid: null, // 城市校验
      },
      bankAddress: [],
      validateAddress: true, // 校验
      isMunicipality: false, // 是否直辖市
      municipalityArr: ['北京市', '上海市', '天津市', '重庆市'],
      rules: {
        // 检验规则
        payeeType: [{ required: true, message: '请选择收款方类型', trigger: 'change' }],
        refName: [
          {
            required: true,
            message: '请输入收款方',
            trigger: ['blur', 'change'],
          },
        ],
        accountName: [{ required: true, message: '请输入收款方名称', trigger: 'blur' }], // 收款方名称
        accountNo: [{ required: true, message: '请输入收款方账号', trigger: 'blur' }], // 收款方账户
        bankName: [{ required: true, message: '请输入开户行银行名称', trigger: 'blur' }], // 开户行银行名称
        branchName: [{ required: true, message: '请输入开户行支行名称', trigger: 'blur' }], // 开户行支行名称
        isDisable: [{ required: true, message: '请选择状态', trigger: 'change' }],
        cityValid: [
          {
            required: true,
            message: '请选开户行所在择城市',
            trigger: ['blur', 'change'],
          },
        ],
      },
      Options: {
        // 字典下拉数据初始化

        payee_type: store.state.dict.options.payee_type, // 收款方类型
      },
      statusOption: [
        { label: '正常', value: 0 },
        { label: '禁用', value: 1 },
      ],
      accountNameOption: [],
      bankNameOption: [],
      refName: null,
    };
  },
  methods: {
    // 获取银行id
    getBankId(val) {
      if (val) {
        const oindex = this.bankNameOption.findIndex((item) => {
          return item.shortName === val;
        });
        this.formData.bankId = this.bankNameOption[oindex].id;
        this.$refs.form.validateField(['bankName']);
      } else {
        this.formData.bankId = null;
        this.formData.bankName = null;
        this.$refs.form.validateField(['bankName']);
      }
    },
    getrefId(val) {
      this.accountNameOption.forEach((item) => {
        if (item.refId === val) {
          this.formData.refName = item.refName;
          this.formData.refId = item.refId;
        }
      });
    },
    getBankNameOption(name) {
      // 获取开户行银行名称
      this.ajax({
        type: 'POST',
        url: '/malicrm/common/queryPayeeAccount',
        data: { shortName: name },
        success: (res) => {
          if (res.code === 200) {
            this.bankNameOption = res.result;
          }
        },
      });
    },
    initBankNameOption() {
      // 失去焦点时重置筛选条件
      if (this.bankNameOption && this.bankNameOption.length <= 0) {
        this.getBankNameOption('');
      }
    },
    getAccountNameOption(name) {
      const searchType = this.formData.payeeType;
      let url;
      let data;
      if (searchType === 3 || searchType === null) {
        return;
      }
      if (searchType === 1) {
        url = '/malicrm/funding/queryByShortName';
        data = { fundingShortName: name };
      }
      if (searchType === 2) {
        url = '/malicrm/storage/queryByName';
        data = { shortName: name };
      }
      if (searchType === 4) {
        url = '/malicrm/business/selectCustomer';
        data = { customerName: name };
      }
      // 获取收款方
      this.ajax({
        type: 'POST',
        url,
        data,
        success: (res) => {
          if (res.code === 200) {
            this.accountNameOption = [];
            this.accountNameOption = res.result.map((item) => {
              let option;
              if (searchType === 4) {
                option = { refName: item.customerName, refId: item.id };
              } else {
                option = { refName: item.shortName, refId: item.id };
              }
              return option;
            });
          }
        },
      });
    },
    initAccountNameOption() {
      // 失去焦点时重置筛选条件
      if (this.accountNameOption && this.accountNameOption.length <= 0) {
        this.getAccountNameOption('');
      }
    },
    payeeTypeChange(val) {
      if (val === 3) {
        this.$refs.form.clearValidate(['refName']);
      }
      this.formData.refName = null;
      this.formData.accountNameOption = [];
      this.getAccountNameOption('');
    },
    getBankAddress(data) {
      const proName = data.province.name;
      if (this.municipalityArr.includes(proName)) {
        this.isMunicipality = true;
        this.formData.cityValid = data.province.name;
      } else {
        this.isMunicipality = false;
        this.formData.cityValid = data.city.name;
      }
      this.formData.addressProvinceId = data.province.id;
      this.formData.addressProvince = data.province.name;
      this.formData.addressCityId = data.city.id;
      this.formData.addressCity = data.city.name;
      if ((this.formData.addressProvinceId && this.formData.addressCityId) || this.municipalityArr.includes(proName)) {
        this.validateAddress = true;
      } else {
        this.validateAddress = false;
      }
      this.$refs.form.validateField('cityValid');
    },
    // 校验只能输入整数
    valInteger(e, prop) {
      let value = e.target.value;
      value = value.replace(/[^\d]+/g, '');
      if (value.length > 0) {
        this.formData[prop] = String(value);
      } else {
        this.formData[prop] = null;
      }
    },
    /**
     * 只能输入中文
     */
    valChinese(e, prop) {
      const val = e.target.value;
      this.formData[prop] = val.replace(/[^\u4E00-\u9FA5]/g, '');
    },
    /**
     * 提交动作
     */
    submit() {
      const proName = this.formData.addressProvince;
      if ((this.formData.addressProvinceId && this.formData.addressCityId) || this.municipalityArr.includes(proName)) {
        this.validateAddress = true;
      } else {
        this.validateAddress = false;
      }
      this.$refs.form.validate((valid) => {
        if (valid && this.validateAddress) {
          this.saveSubmitForm();
        } else {
          this.errorTip('请填写所有带星号的必填项');
          return false;
        }
      });
    },
    /**
     * 保存或编辑表单
     */
    saveSubmitForm() {
      const params = JSON.parse(JSON.stringify(this.formData));
      this.ajax({
        type: 'POST',
        url: '/malicrm/fmPayeeAccount/savePayeeAccount',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('cancel', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    /**
     *  返回操作
     */
    back() {
      this.$emit('cancel', false);
    },
  },
  created() {
    this.getAccountNameOption('');
    this.getBankNameOption('');
    if (this.formType === 2) {
      this.formData = Object.assign({}, this.data);
      this.formData.isDisable = Number(this.formData.isDisable);
      const proName = this.formData.addressProvince;
      if (this.municipalityArr.includes(proName)) {
        this.formData.cityValid = this.formData.addressProvince;
        this.isMunicipality = true;
      } else {
        this.formData.cityValid = this.formData.addressCity;
        this.isMunicipality = false;
      }
      this.bankAddress = [this.formData.addressProvinceId, this.formData.addressCityId, this.formData.areaId];
    }
  },
};
</script>
<style lang="scss">
.payment-account-form {
  .novalidate {
    .el-form-item__content {
      .has-none {
        .el-input--small {
          .el-input__inner {
            border-color: #f56c6c;
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
.payment-account-form .novalidate.edit-type .el-form-item__content .sel-city.has-none:after {
  position: absolute;
  content: '请选开户行所在择城市';
  color: #f56c6c;
  bottom: -28px;
  left: -239px;
  font-size: 12px;
}
.payment-account-form .novalidate.edit-type .el-form-item__content .sel-province.has-none:after {
  position: absolute;
  content: '请选开户行所在择城市';
  color: #f56c6c;
  bottom: -26px;
  left: 0;
}
.payment-account-form .novalidate .el-form-item__content .has-none.sel-city .el-input--small .el-input__inner {
  border-color: #f56c6c !important;
}
.payment-account-form .contact-address .area-range {
  display: none;
}
.payment-account-form .contact-address.municipality .el-form-item__content .range-separator {
  display: none;
}
.payment-account-form .contact-address.municipality .el-form-item__content .sel-city.has-none {
  opacity: 0;
  pointer-events: none;
}
.payment-account-form .contact-address.municipality .el-select.sel-city {
  opacity: 0;
  pointer-events: none;
}
.payment-account-form .contact-address.municipality .el-form-item__content .sel-city.has-none .el-input__suffix {
  pointer-events: none;
  display: none;
}
.maili-edit__form .h88 .el-textarea__inner {
  height: 88px;
  font-size: 14px;
}
</style>
